import pos from '@/services/poscrm'
import util from './util'

export default {
  createPayment(data) {
    pos({
      url: '/api/v1.1/' + data[0].uid + '/Payment/create',
      data: data,
      method: 'post',
    }).then((res) => {
      console.log(res.data)

      let paymentdata = {}

      if (res.data.error.code === 0) {
        paymentdata = {
          payReceiptAt: new Date(),
          paymentId: data[0].id,
          payment: {
            id: data[0].id,
            objectId: data[0].objectId,
            paid: data[0].paid,
            change: data[0].change,
          },
        }
      } else {
        paymentdata = null
      }

      return paymentdata
    })
  },

  paymentDefault(data) {
    let datetime = new Date()
    let objectId = util.createObjectId()
    let id = util.createObjectId()

    return [
      {
        objectId: objectId,
        id: id,
        change: data.change,
        created_at: datetime,
        created_by: {
          username: 'Admin',
          id: 'User001',
        },
        created_by_id: 'User001',
        paid: data.paid,
        payAt: datetime,
        paymentItems: data.paymentItems,
        paymentStatus: data.paymentStatus,
        pos: {
          id: '',
          posNumber: '001',
          objectId: '',
        },
        posId: '',
        shop: {
          id: data.shop.id,
          objectId: data.shop.objectId,
          branchName: data.shop.branchName,
        },
        shopId: data.shopId,
        uid: data.uid,
        updated_at: datetime,
        updated_by: {
          id: 'User001',
          username: 'Admin',
        },
        updated_by_id: 'User001',
      },
    ]
  },
}
