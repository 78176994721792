<template>
  <div class="c-app" style="background-color: #ffffff">
    <div class="c-wrapper">
      <div class="c-body">
        <CContainer>
          <div class="lds-ripple">
            <div></div>
            <div></div>
            <p class="font-weight-bold text-success">กำลังดำเนินการ</p>
          </div>
        </CContainer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import paymentutil from '@/util/payment'
import poscrm from '@/services/poscrm'
import payment from '@/services/payment'
import pos from '@/services/pos'

import orderutil from '@/util/order'
import '@/util/loader.css'

export default {
  data() {
    return {
      status: '',
      paymentType: '',
      paymentStatus: '',
      paymentObjectId: '',
      cartLists: [],
      paymentdetail: {},
    }
  },
  /* beforeRouteEnter(to, from, next) {
    console.log(from)
    let getcart = JSON.parse(sessionStorage.getItem('cart')) || []
    if (from.name === 'OrderSuccess' || getcart.length === 0) {
      window.location.href = '/pickup/menulists'
    } else {
      next((vm) => (vm.previousRoute = from))
    }
  }, */
  computed: {
    ...mapGetters(['shopLineOA', 'shopLineObjectId']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    shop() {
      return this.shopLineOA.find(
        (detail) => detail.objectId === this.shopLineObjectId
      )
    },
    shopId() {
      if (this.shop) {
        return this.shop.id
      }
    },
    orderId() {
      return this.$route.query.orderId
    },
    orderIdRef() {
      return this.$route.query.orderIdRef
    },
  },
  mounted() {
    this.cartLists = JSON.parse(sessionStorage.getItem('cart')) || []
    this.amountForPayment = sessionStorage.getItem('amount')
    this.getCallbackByOrderId()
  },
  methods: {
    ...orderutil,
    async getCallbackByOrderId() {
      console.log(this.orderId)
      console.log(this.orderIdRef)
      let url =
        '/callback/v1.0/' + this.orderId + '/getbyorderId/' + this.orderIdRef
      payment({
        url: url,
        method: 'get',
      }).then((res) => {
        console.log(res.data)

        if (res.data.data !== null) {
          this.paymentdetail = res.data.data
          this.createPayment()
        }
      })
    },
    async createOrder(paymentdata) {
      let data = orderutil.orderDefault(
        this.uid,
        this.shop,
        this.cartLists,
        paymentdata
      )

      console.log(data)

      poscrm({
        url: '/api/v1.1/' + this.uid + '/Receipt/createorder',
        data: data,
        method: 'post',
      }).then((res) => {
        console.log(res.data)
        let objectId = res.data.data.objectId

        console.log(res.data)

        sessionStorage.clear()
        this.$router.push(
          '/pickup/success?objectId=' +
            objectId +
            '&transactionId=' +
            this.transactionId
        )
      })
    },
    async createPayment() {
      let amount = Number(this.paymentdetail.detail.receive_amount)
      console.log(amount)
      let paymentdata = {
        uid: this.uid,
        shop: this.shop,
        shopId: this.shopId,
        paid: amount,
        change: 0,
        paymentStatus: 'A',
        paymentItems: [
          {
            detail: this.paymentdetail,
            paymentType: 10,
            customPaymentId: '620395be19d7f035bd1fee81',
            customPaymentName: 'ThaiDotCom Payment',
            amount: amount,
            inputAmount: amount,
          },
        ],
      }

      console.log(paymentdata)

      let paymentdefault = await paymentutil.paymentDefault(paymentdata)

      console.log(paymentdefault)

      poscrm({
        url: '/api/v1.1/' + this.uid + '/Payment/create',
        data: paymentdefault,
        method: 'post',
      }).then((res) => {
        console.log(res.data)

        let dataForCreate = {}

        if (res.data.error.code === 0) {
          dataForCreate = {
            payReceiptAt: new Date(),
            paymentId: paymentdefault[0].id,
            payment: {
              id: paymentdefault[0].id,
              objectId: paymentdefault[0].objectId,
              paid: paymentdefault[0].paid,
              change: paymentdefault[0].change,
            },
          }
          console.log('--pay def--')
          console.log(paymentdefault)

          console.log(dataForCreate)
          this.createOrder(dataForCreate)
        } else {
          dataForCreate = null
        }
      })
    },
  },
}
</script>
